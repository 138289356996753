<template>
  <div id="addScholarship" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">ESCOLARIDAD</v-row>
      <v-container fluid class="addCategory">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" xs="6" class="marginCol">
            <v-row>
              <div class="form-group mt-9 mb-5">
                <label for="scholarship">Escolaridad</label>
                <input
                  type="text"
                  name="scholarship"
                  id="scholarship"
                  placeholder="Escolaridad"
                  class="inputs"
                  autocomplete="false"
                  v-model="escolaridad"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" xs="6" class="mt-12">
            <button
              class="botonAmarillo mt-5"
              style="float: none"
              @click="validacion()"
            >
              Guardar
            </button>
            <router-link to="/scholarship" id="backScholarship">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Escolaridad</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar la Escolaridad?
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" text @click="cancel()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Escolaridad</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Sesion from "@/components/Sesion";
import Loader from "@/components/Loader";
export default {
  components: {
    Sidebar,
    Sesion,
    Loader,
  },
  data() {
    return {
      search: "",
      show: false,
      expiration: false,
      dialog: false,
      escolaridad: "",
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
    };
  },
  methods: {
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/escolaridad",
          {
            nombreEscolaridad: this.escolaridad,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.escolaridad = "";
          this.respuesta = "La escolaridad fue guardada con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.escolaridad = "";
            this.respuesta = "La escolaridad no fue almacenada";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function () {
      this.errors = [];
      if (this.escolaridad == "" || this.escolaridad == " ") {
        this.errors.push("Se debe especificar la Escolaridad.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    cancel() {
      this.scholarship = "";
      this.advice = false;
    },
    aux() {
      this.confirmation = false;
      this.scholarship = "";
      this.$router.push("/scholarship");
    },
    cancel() {
      this.advice = false;
      this.escolaridad = "";
    },
  },
  created() {},
};
</script>